@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

/*
@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Regular.woff2") format("woff2"),
       url("../fonts/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/Inter-UI-Italic.woff2") format("woff2"),
       url("../fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-UI-Medium.woff2") format("woff2"),
       url("../fonts/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 600;
  src: url("../fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("../fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-UI-Bold.woff2") format("woff2"),
       url("../fonts/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 800;
  src: url("../fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("../fonts/Inter-UI-BoldItalic.woff") format("woff");
}
*/
