@charset "UTF-8";

/* light theme color */
$light-background: #fff;
$light-background-secondary: #eaeaea;
$light-background-header: #fafafa;
$light-color: #222;
$light-color-secondary: #999;
$light-border-color: #dcdcdc;

/* dark theme colors */
$dark-background: #191419; //#292a2d;
$dark-background-secondary: #3b3d42;
$dark-background-header: #161116; //#252627;
$dark-color: #e9e9f3;
$dark-color-secondary: #73747b;
$dark-border-color: #4a4b50;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
